import { useMemo, useCallback }     from 'react'

import { useAtom, atom }            from 'jotai'
import { useAtomValue, useSetAtom } from 'jotai'
import { focusAtom }                from 'jotai-optics'
import { atomWithStorage }          from 'jotai/utils'

import { _project }                 from './store.projects'
import { _isometry }                from './store.isometry'
import { active_project }           from 'state/store.global'
import { active_subiso }            from './store.subiso'

import { fn3, fn4 }                 from 'utilities/utility.misc'

export { atom, useAtom, useAtomValue, useSetAtom, atomWithStorage }

export const useValue  = (atom) => useAtomValue(atom)
export const useUpdate = (atom) => useSetAtom(atom)
export const useGet    = (atom) => useAtomValue(atom)
export const useSet    = (atom) => useSetAtom(atom)

export const useFocusSet     = (atom, key)    => useSetAtom   ( focusAtom( useMemo(() => atom, [atom]), useCallback(o => o.prop(key), []) ) )
export const useFocusGet     = (atom, key)    => useAtomValue ( focusAtom( useMemo(() => atom, [atom]), useCallback(o => o.prop(key), []) ) )
export const setFocus        = (atom, key)    => useSetAtom   ( focusAtom( useMemo(() => atom, [atom]), useCallback(o => o.prop(key), []) ) )
export const getFocus        = (atom, key)    => useAtomValue ( focusAtom( useMemo(() => atom, [atom]), useCallback(o => o.prop(key), []) ) )
export const useFocusSetAtom = (atom, key)    => useAtom      ( focusAtom( useMemo(() => atom, [atom]), useCallback(o => o.prop(key), []) ) )
export const useCustomFocus  = (atom, optics) => useAtom      ( focusAtom( useMemo(() => atom, [atom]), useCallback(optics, []) ) )

export const get = (atom, key, sub = false, alt = []) => {
 
  const { pin, pid } = useGet(active_project)
  return sub 
    ? getFocus(atom, key)?.[pin]?.[pid]?.[sub] ?? alt
    : getFocus(atom, key)?.[pin]?.[pid] ?? alt
}

export const set = (atom,key) => {
  const setter = setFocus(atom,key)
  const { pin, pid } = useGet(active_project)
  return ( update ) => setter(a => fn3(a,pin,pid,update))
}

export const zet = (atom,key,sub) => {
  const setter = setFocus(atom,key)
  const { pin, pid } = useGet(active_project)
  return ( update ) => setter(a => fn4(a,pin,pid,sub,{'NAME':sub, 'RES':update}))
}

export const activeProject = () => {
  const { pin, pid } = useGet(active_project)
  return { pin, pid }
}

export const activeSubiso = () => {
  const activesub = useGet(active_subiso)
  return activesub
}