import styled from 'styled-components/macro'
import ReactSlider from 'react-slider'
import { BREAK_ } from 'constants'


export const Control = styled(ReactSlider)`
  width: ${({isDesktops})=>!isDesktops ? "85%" : "70%"};
  height: 25px;
  position: ${({isDesktops})=>!isDesktops ? "absolute" : ""};
  right:${({isDesktops})=>!isDesktops ? "0" :"0"} ;
`
export const Main = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  position:absolute;
  bottom:100px;
  flex-direction: column;
  padding-bottom: 16px;
  ${BREAK_.md_dn} {
    bottom:0px;
    padding-bottom: 100px;
  }
  background: transparent;
`

export const Text = styled.div`
  position: absolute;
  top: -30px;
  left: 50%;
  font-size: 1.5rem;
  font-weight: 700;
  transform: translatex(-50%);
  /* border:1px solid black; */
  width: 100%;
  text-align: center;
`