import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import PropTypes                                  from "prop-types";
import React, { useState }                        from "react";
import { Swiper, SwiperSlide }                    from "swiper/react";
import { useRelatedUnits }                        from "./hooks";
import Unit                                       from "./Unit.model";
import UnitCard                                   from "./UnitCard";
import { useTranslation }                         from "react-i18next";
import { useNavigate }                            from "react-router-dom";
import { getFocus, useAtom, useGet }                       from "state/jotai";
import { active_project, params_query, previousUrl }                         from "state/store.global";
import { _project }                               from "state/store.projects";
import { animateScroll }                          from "react-scroll";
import { Grid }                                   from "components/List/Grid/Grid";
import useUnitPage from "./useUnitPage";
import { useBreak } from "hooks/useBreak";
import { Scrollbar } from "swiper";
import 'swiper/css/scrollbar';
import { CustomButton } from "./NewReservationButton";

function RelatedUnits({ unit }) {
  const isDesktop      = useBreak('md_up')
  const sliderBreakPoints = {
    300: {
      slidesPerView: 1.3
    },
    500: {
     slidesPerView: 2,
    },
    1400: {
     slidesPerView: 2,
     },
     2000: {
       slidesPerView: 2,
     },
  }
  
  const relatedUnits   = useRelatedUnits(unit.getId());
  const [refContainer] = useUnitPage();
  
  const isMobile   = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const navigateTo = useNavigate()
  const params     = useGet(params_query);

  const { pin, pid } = useGet(active_project)
  const buttonsLabel = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []

  const { i18n: { language } } = useTranslation()

  const [, setPreviousUrl] = useAtom(previousUrl);

  const click1 = (id) => {
    setPreviousUrl(location.pathname)
    navigateTo(`/${language}/unit/${id}${params.length ? params : ''}`);

    if(isDesktop) {
      refContainer?.scrollToTop()
    }else {
      animateScroll.scrollToTop({
        container: refContainer,
        duration : 500
      });
    }
    log({ 
      action: 'clicked_apartment_on_listview',
      unitId: id
    })
  };

  const handleClick = () => navigateTo(`/${language}/list${params.length ? params : ''}`)
  const mode        = useState("grid");
  const click       = handleClick;

  const list    = { extra: [], unitErrMsg: (()=> <Box sx={{textAlign: 'center', marginTop: '3rem'}}>
  Der er ikke nogle lignende boliger, der er ledige
</Box>)(), click: click1, mode: mode[0] };

  return (
    <Box
      sx={{
        paddingLeft: isMobile ? 3: 6,
        pt         : 4,
        width      : '100%',
        "& .related-units-slider": { marginBottom: "14px" }
      }}
    >
      <Typography
        variant   = "h4"
        sx        = {{ mb: 3, fontSize: "1.25rem" }}
        className = "h3"
      >
        { buttonsLabel?.filter( btn => btn.UILabelProperty === 'MåskeTekst')[0]?.Label }
      </Typography>
      {!relatedUnits.length && <Box sx={{textAlign: 'center', marginTop: '3rem'}}>
        Der er ikke nogle lignende boliger, der er ledige
      </Box>}
      <>
        <Swiper
          modules={[Scrollbar]}
          slidesPerView = {"auto"}
          spaceBetween  = {isMobile ? 15 : 8}
          scrollbar={{ draggable: true }}
                  // breakpoints = {sliderBreakPoints} 
        >
          {relatedUnits.slice(0,3).map((unit, i) => (
            <SwiperSlide className="related-units-slider" key = {i} style = {{ width: "fit-content", height: "auto", borderRadius: '6px', overflow: 'hidden' }}>
              {/* <RelatedUnitCard unit={unit} /> */}
              {/* <UnitCard unit={unit} /> */}
              <Grid {...list} units = {[unit]} />
            </SwiperSlide>
          ))}
        </Swiper>
      </>

      <Box
        sx={{
          mt: 4,
        }}
      >
      <CustomButton padding={"12px 24px"} clr={clr} className="eus-button-2" variant = "primary" onClick = {handleClick}>{ buttonsLabel?.filter( btn => btn.UILabelProperty === 'SeAlleBoliger')[0]?.Label }</CustomButton>
      </Box>
    </Box>
  );
}

RelatedUnits.propTypes = {
  unit: PropTypes.instanceOf(Unit).isRequired,
};

export default RelatedUnits;
