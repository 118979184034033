import { List } from 'components/List/List'
import { DualMenu } from 'components/DualMenu/DualMenu'
import { TopMenu } from 'components/TopMenu/TopMenu'
import { useBreak } from 'hooks/useBreak'
import { Scrollbar } from 'react-scrollbars-custom'

export const ViewList = () => {
  const isDesktop    = useBreak('md_up')

  return (
    <>
   {
      isDesktop ?
      <Scrollbar trackYProps={{
        renderer: (props) => {
         
          const { elementRef, ...restProps } = props;
          // console.log(restProps,"pprrr")
          return <span {...restProps} ref={elementRef}
          className="trackY" style={{ ...restProps.style }} />;
       
        },
      }}>
        <RenderComponent />
      </Scrollbar>
      :
      <RenderComponent />
      
    }
    
    </>
    
  )
}


const RenderComponent = () => {
  return (
    <>
      <List />
    </>
  )
}