


import styled from 'styled-components/macro'
import { useState } from 'react'
// import { clr } from '@/project_colors'

export const Thumb = (props) => {
  
  // const fill = clr.interval_thumb
  const fill ="#7C0B2B"
  const svgPos = { 
    overflow: 'visible', 
    width: '100%',
    height: '100%',
  }

  const thumbBackground = {
    backgroundColor: '#ffffff',
    borderRadius: '50%',
    position: 'absolute', 
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%', 
    height: '100%',
  }

  return (
    <StyledThumb {...props}>
      <div style={thumbBackground}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='3 3 42 42'
          style={svgPos}
        >
          <defs>
            <clipPath id='ab'>
              <path fill='none' d='M0 0h53.823v53.822H0z'/>
            </clipPath>
          </defs>
          <g clipPath='url(#ab)' transform="translate(-3.0634689,-1.5639996)">
            <path
              fill={fill}
              d='
                m27.195 1.564
                a24.127 24.127 0 1 0 12.06 3.233 24.128 24.128 0 0 0-12.06-3.233
                m-8.12 28.079
                a1.725 1.725 0 1 1-2.43 2.448l-5.171-5.167
                a1.725 1.725 0 0 1-.009-2.438l.009-.009 5.171-5.171
                a1.725 1.725 0 1 1 2.43 2.448l-3.947 3.937Z
                m8.119-.5
                a3.446 3.446 0 1 1 2.438-1.009 3.446 3.446 0 0 1-2.437 1.008
                m15.72-2.218-5.171 5.167
                a1.725 1.725 0 0 1-2.43-2.448l3.945-3.952-3.947-3.947
                a1.725 1.725 0 1 1 2.43-2.448l5.171 5.171
                a1.724 1.724 0 0 1 .009 2.438l-.009.009z'
            />
          </g>
        </svg>
      </div>
    </StyledThumb>
  )
}

const StyledThumb = styled.div`
  position: absolute;
  bottom : -6px;
  height: 35px;
  width: 35px;
  text-align: center;
  border-radius: 50%;
`
