import styled from 'styled-components/macro'
import { useState } from 'react'
import { getFocus, useGet } from 'state/jotai'
import { _project } from 'state/store.projects'
import { active_project } from 'state/store.global'

export const Thumb = ({props, northDeg}, state) => {
  const { pin, pid } = useGet(active_project) 
  const buttonsLabel  = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []

  const fill = clr?.buttons
  
  return (
    <StyledThumb {...props}>
       <div style={{display: "flex", alignItems: "start", position: "absolute", top: "-30px", left: "-20px", justifyContent: "center", flexDirection: "column", color: "#000"}}>
            <div className='paragraph2-light' style={{ fontWeight: "500", fontSize: "14px", lineHeight: "18px", display: "flex", gap: "4px" }}>
                <div>{northDeg}</div>
                <div style={{ textAlign: "center" }}>{buttonsLabel?.filter( btn => btn?.UILabelProperty === 'grader')[0]?.Label}</div>
            </div>
        </div> 
      <SVG></SVG>
    </StyledThumb>
  )
}

const SVG = ({children}) => {

  const [cursor, setCursor] = useState('grab')

  return (
    <Svg 
      viewBox={`0 0 100 100`}
      onMouseDown={()=>setCursor('grabbing')}
      onMouseUp={()=>setCursor('grab')}
      onMouseLeave={()=>setCursor('grab')}
      onMouseEnter={()=>setCursor('grab')}
      style={{cursor:cursor, background: clr.primary}}
      >
      { children }
    </Svg>
  )
}


const Svg = styled.div`
  width:30px;
  height:30px;
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  border-radius:50%;
  &: focus-visible {
    outline: none;
  }
`

const StyledThumb = styled.div`
  position:relative;
  height: 25px;
  line-height: 25px;
  width: 25px;
  text-align: center;
  color: #fff;
  border-radius: 50%;
  outline: none;
`