import { Box, Grid, Typography } from "@mui/material";
import { STATICS } from "api/api";
import React from "react";
import { getFocus, useGet } from "state/jotai";
import { active_project } from "state/store.global";
import { _project } from "state/store.projects";

function Footer() {
  const { pin, pid } = useGet(active_project);
  const buttonsLabel = getFocus(_project, "buttonsLabel")?.[pin]?.[pid] ?? [];
  const contactData =
    getFocus(_project, "ContactProperties")?.[pin]?.[pid] ?? [];

  return (
    <div style={{ width: "100%" }}>
      <Box
        sx={{
          mt: 5,
          pb: 55,
          pr: { md: 10, xs: 4 },
          pl: { md: 5, xs: 4 },
          py: { md: 0, xs: 2 },
          lineHeight: 1.4,
          position: 'relative', // Relative positioning for parent container
          maxWidth: 756,
          margin: "auto",
        }}
      >
        <Box
          sx={{
            mb: 2,
            mt: 3
          }}
        >
          <span className="h3">
            {" "}
            {
              buttonsLabel?.filter(
                (btn) => btn.UILabelProperty === "SidefodKontaktOverskrift"
              )[0]?.Label
            }
          </span>
        </Box>

        {contactData?.map((item, i) => {
          // Only render the Grid if there is data
          if (
            !item?.Name &&
            !item?.AddressLine1 &&
            !item?.AddressLine2 &&
            !item?.Phone &&
            !item?.Email &&
            !item?.ImageUrl
          ) {
            return null;
          }

          return (
            <Grid container sx={{ mb: 5 }} spacing={3} key={i} alignItems="center">
              {/* Only render the name and address if available */}
              {(item?.Name ||
                item?.AddressLine1 ||
                item?.AddressLine2 ||
                item?.Phone ||
                item?.Email) && (
                <Grid item lg={6} xs={12} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  <Box>
                    {item?.Name && (
                      <>
                        <span className="h4"> {item?.Name}</span> <br />
                      </>
                    )}
                    {item?.AddressLine1 && (
                      <>
                        <span className="paragraph1-light">
                          {item?.AddressLine1}
                        </span>{" "}
                        <br />
                      </>
                    )}
                    {item?.AddressLine2 && (
                      <>
                        <span className="paragraph1-light">
                          {item?.AddressLine2}
                        </span>{" "}
                        <br />
                      </>
                    )}
                    {item?.Phone && (
                      <>
                        <span
                          className="paragraph1-light"
                          style={{
                            textDecoration: "underline",
                            color: clr.primary_text,
                          }}
                        >
                          {item?.Phone}
                        </span>
                        <br />
                      </>
                    )}
                    {item?.Email && (
                      <Typography
                        style={{ color: clr.primary_text }}
                        component="a"
                        href={`mailto: ${item.Email}`}
                      >
                        <span className="paragraph1-light">
                          {item?.Email}
                        </span>
                      </Typography>
                    )}
                  </Box>
                </Grid>
              )}

              {/* Only render the image if the ImageUrl is available */}
              {item?.ImageUrl && (
                <Grid
                item
                lg={6}
                xs={12}
                sx={{
                  textAlign: { lg: "center", xs: "left" },
                  display: 'flex',
                  justifyContent: { xs: "flex-start", lg: 'flex-end'}
                }}
                >
                  <Box sx={{ width: 182 }}>
                    <Box component="img" src={`${STATICS}${item?.ImageUrl}`} alt="footerimage" style={{ cursor: 'pointer' }}  />
                  </Box>
                </Grid>
              )}
            </Grid>
          );
        })}

        <div style={{ height: 50 }} />
      </Box>
    </div>
  );
}

export default Footer;
