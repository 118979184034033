import styled, {css} from 'styled-components'
import ReactSlider   from 'react-slider'

export const Range = styled(ReactSlider)`
  width: calc(100% - 24px);
  height: 25px;
  cursor: pointer;
  display:flex;
  align-items:center;
`
export const Values = styled.div`
  width:100%;
  display:flex;
  position:relative;
  justify-content: space-between;
`
export const Value = styled.div( ({right}) => css`
  color: ${({ clr }) => `${clr?.primary_text}`}
`)