import { Box, Grow, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, SvgIcon, useMediaQuery, } from '@mui/material'
import { Icon } from 'components/Icons'
import { useRef, useState } from 'react'
import useLanguage from './useLanguage'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import { _project } from 'state/store.projects'
import { getFocus, setFocus, useAtom, useGet } from 'state/jotai'
import { active_project, current_language, params_query } from 'state/store.global'
import { dropdownOptions } from 'components/Dropdown2/Dropdown2.Data'
import { _bootstrap } from 'state/store.bootstrap'
import { PoweredBy } from 'components/PoweredBy/PoweredBy'
import { getProjectData } from 'state/jotai.projectDataHooks'

function LanguageMenu() {
  const isMobile                             = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const [activeLang, setActiveLang, options] = useLanguage()
    // const options = getProjectData('sorteroptions', 0, [])

  const [anchorEl, setAnchorEl] = useState(null)
  const anchorRef               = useRef(null)
  const [isOpen, setIsOpen]     = useState(false)
  const languages               = getFocus(_bootstrap, 'languages')
  const [, setCurrent_language] = useAtom(current_language);

  const { i18n, i18n: { language } } = useTranslation()
  const navigateTo                   = useNavigate()
  const params                       = useGet(params_query);
  const here                         = useLocation().pathname
  const { pin, pid }                 = useGet(active_project)
  const buttonsLabel                 = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []

  const handleToggle = () => {
    setAnchorEl(anchorRef.current)
    setIsOpen(isOpen => !isOpen)
  }

  const handleClose = () => {
    setIsOpen(false)
    setAnchorEl(null)
  }

  const handleChange = id => {
    if (languages.includes(id)) {
      setCurrent_language(id)
      setActiveLang(id)
      i18n.changeLanguage(id)
      handleClose()
      navigateTo(here.replace(language, id) + (params.length ? params : ''))
    }
  }

  const totalItems = options?.length;

  return (
    <>
    {
    languages?.length > 1 &&
      <Box        sx      = {{ display: 'flex', alignItems: 'center', zIndex: 1000, userSelect: 'none', }} ref = {anchorRef} >
      <IconButton onClick = {handleToggle}>
      <Icon       color   = {clr.primary_text} icon                                                            = 'globe' isFaIcon = {true} fontSize = 'inherit' />
      <Icon       color   = {clr.primary_text} icon                                                            = 'caretDown' sx   = {{ position: 'absolute', right: -8, bottom: 13, fontSize: 10, transition: 'transform 0.3s', transform: isOpen ? 'rotate(180deg)' : 'rotate(0)', fill: clr.primary_text }} ></Icon>
        </IconButton>
        <Menu
          anchorEl            = {anchorEl}
          open                = {isOpen}
          onClose             = {handleClose}
          anchorOrigin        = {{ vertical: 'bottom', horizontal: 'right', }}
          transformOrigin     = {{ vertical: 'top', horizontal: 'right', }}
          anchorReference     = {isMobile ? 'anchorPosition' : 'anchorEl'}
          anchorPosition      = {{ top: 70, left: 400 }}
          PaperProps          = {{ overflow: "hidden !important", elevation: 1, sx: { width: isMobile ? '100%' : 300, left: isMobile ? '0 !important' : 0, maxWidth: '100%', }, }}
          sx                  = {{ '& .MuiBackdrop-root': { backgroundColor: 'rgba(0, 0, 0, 0.25)', }, '& .MuiPaper-root': { backgroundColor: `${clr.primary}e6`, borderRadius: "6px", overflow: "hidden !important" }, '& .MuiList-root': { padding: "0px", }, }}
          TransitionComponent = {Grow}
        >
          <div style     = {{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: "16px", borderBottom: `1px solid ${clr.primary_text} ` }}>
          <div className = 'h5'>
            { buttonsLabel?.filter( btn => btn?.UILabelProperty === "LanguagePickerText")[0]?.Label }
              {/* Vælg det sprog du ønsker */}
            </div>
            <div style = {{ cursor: 'pointer', marginRight: '10px',position : 'absolute',right : '10px' }} onClick = {() => { setIsOpen(false) }}><Icon icon = 'close' isFaIcon = {true} size = "xl" color = {clr?.primary_text} /></div>

          </div>

          <div style = {{ overflowY: "auto",overflowX : 'hidden', maxHeight: "200px" }}>
            <>
              {options.map((item, i) => {
                return (

                  <div style = {{ padding: '0px 24px' }} key = {i}>

                    <MenuItem key = {i} onClick = {() => handleChange(item.id)} sx = {{ gap: '10px', borderBottom: i === totalItems - 0 ? 'none' : `1px solid ${clr.primary_text} `, padding: i === 0 ? '24px 32px 16px 0px ' : '16px 0px',marginBottom: i === totalItems - 1?"32px" : "none" }}>
                      <ListItemIcon>
                        <SvgIcon sx = {{ fontSize: 25 }}>{item.icon}</SvgIcon>
                      </ListItemIcon>
                      <div className = 'paragraph1-regular'
                           style     = {{ color: clr.primary_text }}
                      >
                        {item.label}
                      </div>
                    </MenuItem>

                  </div>
                )
              })}
            </>
          </div>
        </Menu>
      </Box>
      }
    </>
  )
}

export default LanguageMenu
