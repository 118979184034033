import { useState, useEffect }      from 'react'
import { _project }                 from 'state/store.projects'
import { get, getFocus, useGet }                      from 'state/jotai'
import { Legend, Dot, Status, Txt } from './ColorLegend.style'
import { motion }                   from 'framer-motion'
import { useBreak }                 from 'hooks/useBreak'
import { active_project } from 'state/store.global'
import { Box, Grid } from '@mui/material'
import { _bootstrap } from 'state/store.bootstrap'

export const DesktopColorLegend = () => {

  const [comb, setComb] = useState()
  const type = getFocus(_bootstrap, "type");
  const raw = get(_project, 'unitsraw', 'Units') 
  const { pin, pid } = useGet(active_project) 
  const options      = getFocus(_project, 'filteroptions')?.[pin]?.[pid] ?? []
  const unitStatus = options.filter( o => o.Type === "MultiSelect" && o.Label === "Status")[0]?.PossibleValues

  const buttonsLabel  = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []

  useEffect(()=>{
    if ( raw.length ) {
      setComb([...new Set( raw.map(i => {
        return `${i.Data.UnitStatusName.Value}___${i.Data.UnitColor.Value.toLowerCase()}`
      }))])
    }
  },[raw])
  const isDesktop = useBreak('md_up')

  const animation = {
    initial   : { opacity: '0' },
    animate   : { opacity: '1' },
    exit      : { opacity: '0' },
    transition: { duration:0.2, ease:'linear' }
  }


  if (raw.length && comb) return (
    <>
      <Legend {...animation} cssStyle={{
        backgroundColor: `${clr?.primary}bf`,
        border: `1px solid ${clr?.primary}`,
        width: '260px',
        padding: '16px',
        position: 'absolute',
      }}
      type={type}
      >
        <div>
        <Box className="h5" sx={{marginBottom: '1rem', color:clr?.primary_text}}>{ buttonsLabel?.filter( btn => btn?.UILabelProperty === 'StatusOverskrift')[0]?.Label }</Box>
        <Grid container marginTop={1} style={{ display: 'flex', flexDirection: "column", gap: "8px" }}>
        {
            comb.map(i => {
                const name = i.split('___')[0]
                const color = i.split('___')[1]
                return (
                <Grid key={i} xs={6}>
                    <Status key={i} clr={clr}>
                        <Dot style={{background:color, width: '8px', height: '8px'}}/>
                        <Txt className='label-regular' children={name} />
                    </Status>
                </Grid>  
            )
        })
    }
        </Grid>
        </div>
       
      </Legend>
    </>
  )

}