import eachOfLimit                from 'async/eachOfLimit'
import { useEffect }              from 'react'
import { useFocusSet, useGet }    from 'state/jotai'
import { useFetch }               from 'hooks/fetch/useFetch'

import { _map }                   from 'state/store.map'
import { _bootstrap }             from 'state/store.bootstrap'

import { array }                  from 'utilities/utility.misc'
import { preloadImage }           from 'utilities/utility.images.jsx'

import { MAP_FRAMES, MAP_CONFIG, MAP_SVGS } from 'api/api'

export const PreloadFrames_Map = () => {

  const type    = useGet(_bootstrap).type
  const file    = 'main/Poly/PolyPreload.jsx'
  const poly    = type === 'poly'
  const config  = useFetch({ url:poly?MAP_CONFIG:null, file }).D
  const svgs    = useFetch({ url:poly?MAP_SVGS:null,   file }).D
  const setViews   = useFocusSet(_map, 'views')
  const setPercent = useFocusSet(_map, 'percent')
  const setStatus  = useFocusSet(_map, 'status')
  const setConfig  = useFocusSet(_map, 'config')
  const setInitial = useFocusSet(_map, 'initial')
  const setDim     = useFocusSet(_map, 'dimensions')
  const setReady   = useFocusSet(_map, 'ready')
  const setSvgs    = useFocusSet(_map, 'svgs')

  useEffect(()=>{
    if ( svgs && config && poly ) {

      const i = config
      const j = svgs
      
      setSvgs(j)
      setConfig(i)
      setInitial(i.indexOf( i.filter( k => k.FIRST_TO_SHOW )[0] ) )

      const NUM = i.length
      const FNC = x => `${MAP_FRAMES}/${i[x].NAME}.${i[x].EXT}`
      const COL = array(NUM, FNC)
      const ARR = []
      eachOfLimit(COL, 20,
        ( val, idx, callback ) => {

          const IMG      = preloadImage(val)
          const STATUS   = ( idx !== NUM - 1 ) ? 'FETCHING' : 'DONE'
          const PERCENT  = ( idx * ( 100 / NUM) ).toFixed()

          ARR[idx] = IMG

          setPercent( PERCENT )
          setViews  ( ARR )
          setStatus ( STATUS )

          IMG.onload = ( ) => callback()

        },
        () => {
          // console.log( 'maps for poly project eido finished' )
          setReady(true)
        }
      )
    }

  },[poly, config])

  return null
}

// Preload.whyDidYouRender = true