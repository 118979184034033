import { get, useAtom, useGet, getFocus } from "state/jotai";
import { _isometry } from "state/store.isometry";
import { _subiso } from "state/store.subiso";
import {
  isMulti_project_active,
} from "state/store.global";
import { useLocation } from "react-router-dom";
import { Nav } from "./TopMenu.style";
import { useParams } from "react-router-dom";
import { useBreak } from "hooks/useBreak";
import NavItems from "components/Nav/NavItems";
import { iso_free, active_project } from "state/store.global";
import styled from "styled-components/macro";
import { BREAK_ } from "constants";
import { _project } from "state/store.projects";
import { current_language } from "state/store.global";

import { _bootstrap } from "state/store.bootstrap";
import { LogoWrap } from "components/Nav/Nav.Desktop.style";
import { Logo } from "components/Nav/Nav.Logo";
import { NavDesktop } from "components/Nav/Nav.Desktop";
import { useEffect, useState } from "react";
import { BackButton } from "components/Button/BackButton";

export const TopMenu = ({ left, right, nav }) => {
  
  const rmode = useAtom(iso_free)[0];
  const isDesktop = useBreak("md_up");
  const sub = useParams("sub").sub;

  const multiActive = useAtom(isMulti_project_active)[0];

  const here = useLocation().pathname;

  const hidePropertyDropdownAtDesktop = ["unit", "downloads"].some((i) =>
    here.includes(i)
  );
  const atModel = here.includes("isometry");
  const atList = here.includes("list");
  const atFavorites = here.includes("favorites");
  const atUnit = here.includes("unit");
  const atCompare = here.includes("compare");
  const atDownloads = here.includes("downloads");
  const atGallery = here.includes("gallery");

  const hideFilterMultiActiveTrue = [
    "isometry",
    "compare",
    "gallery",
    "downloads",
    "unit",
  ].some((i) => here.includes(i));
  const hideFilterMultiActiveFalse = [
    "compare",
    "gallery",
    "downloads",
    "unit",
  ].some((i) => here.includes(i));
  const filterShouldShow = !multiActive
    ? !hideFilterMultiActiveTrue
    : !hideFilterMultiActiveFalse;

  const { pin, pid } = useGet(active_project);
  const buttonsLabel = getFocus(_project, "buttonsLabel")?.[pin]?.[pid] ?? [];
  const projectType = getFocus(_bootstrap, "type");
  const [currentLanguage] = useAtom(current_language);

  const dropdownOptions = getFocus(_bootstrap, "projectnames");
  const [face, setFace] = useState("");
  const menus = getFocus(_project, "menuItems")?.[pin]?.[pid] ?? [];
// console.log(menus,"menus")
  // Find the menu item with Order "10" and extract the URL
  const menuItemOrder10 = menus.find((item) => item.Order === "10");
  const logoUrl = menuItemOrder10 ? menuItemOrder10.URL : 'https://grunwaldshave.dk/' ;


  useEffect(() => {
    if (Object.keys(dropdownOptions).length && pid) {
      setFace(
        dropdownOptions[currentLanguage].filter((d, i) => d.PID === pid && i === pin)[0]
          ?.URLSafe
      );
    }
  }, [pid, pin, currentLanguage]);


  return (
    <>
      {
        isDesktop && (
          <Nav clr={clr} left={left} right={right} nav={nav} layout>
            <>
              <span>
                <LogoWrap
                  style={{paddingTop:"12px", paddingBottom:"12px"}}
                   >
                    <Logo device='desktop' key='desktopLogo' link={logoUrl}/>
                  </LogoWrap>

                {isDesktop &&
                  (
                    atCompare ||
                    atFavorites ||
                    atDownloads ||
                    atGallery) && (
                      <BackButton color={atUnit ? `${clr?.icons}` : `${clr?.primary_text_passive}`}/>
                  )}
              </span>
              <span>
                { projectType === "poly" && !hidePropertyDropdownAtDesktop && !(rmode && !isDesktop) && (
                    // <Dropdown2 />
                    <Face>Du kigger lige nu på : {face}</Face>
                  )}
              </span>
              <span>
                {isDesktop && <NavItems hideNav={false} />}
                {isDesktop && <NavDesktop />}
              </span>
            </>
            {/* )} */}
          </Nav>
        )
      }
    </>
  );
};

const Box = styled.div`
  gap: 5px;
  cursor: pointer;
  padding: 5px 15px;
  z-index: 5;
  align-items: center;
  display: flex;
  gap: 5px;
  user-select: none;
  ${BREAK_.md_up} {
    position: fixed;
    top: 92px;
    left: 48px;
    padding: 0;
  }
`;
const Text = styled.div`
  white-space: nowrap;
  color: ${({ clr, atUnit }) =>
  atUnit ? `${clr?.icons}` : `${clr?.primary_text_passive}`};
`;

const Face = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;
