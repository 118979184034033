import { useEffect }      from 'react'
import { Gallery }        from 'components/Gallery/Gallery'
import styled             from 'styled-components/macro'
import { scrolly }        from 'state/store.global'
import { useGet, useSet } from 'state/jotai'
import { usePrevious }    from 'hooks/usePrevious'
import { compares }       from 'state/store.global'
import { DualMenu } from 'components/DualMenu/DualMenu'
import { BREAK_ } from 'constants'
import { useBreak } from 'hooks/useBreak'
import { Scrollbar } from 'react-scrollbars-custom'

export const ViewGallery = () => {
  const isDesktop    = useBreak('md_up')

  return (
    <>
   {
      isDesktop ?
      <Scrollbar trackYProps={{
        renderer: (props) => {
          const { elementRef, ...restProps } = props;
          return <span {...restProps} ref={elementRef}
          className="trackY" style={{ ...restProps.style }} />;
        },
      }}>
        <RenderComponent />
      </Scrollbar>
      :
      <RenderComponent />
    }
    </>
  )
}


const RenderComponent = () => {
  const setScroll = useSet(scrolly)
  const scroll    = useGet(scrolly)
  const comps     = useGet(compares)

  useEffect(()=>{ setScroll(0) },[])

  const prevScroll = usePrevious(scroll)

  const a = scroll?.toFixed(0) ?? 0
  const b = prevScroll?.toFixed(0) ?? 100

  return (
    <>
      <Container>
        <Gallery />
      </Container>
      {/* {a < b || a < 30 ? <DualMenu mode={'list'} /> : null} */}
    </>
  )
}

const Container = styled.div`
  display:flex;
  flex-direction:column;
  width: 100%;
  height: 100%;
`
