import { InnerSection, Label, Main } from './Isometry.Controls.Arrows.style'
import { useState }    from 'react'
import { animate }     from 'framer-motion'
import { Arrow }       from './Isometry.Controls.Arrows.assets'
import { useBreak }    from 'hooks/useBreak'
import { motion }      from 'framer-motion'
import { active_project, rotate_by_slider, vis_showOldSlider } from 'state/store.global'
import { getFocus, setFocus, useGet, useSet } from 'state/jotai'
import { _project } from 'state/store.projects'
import { getIsoData } from 'state/jotai.isometryDataHooks'
import { _bootstrap } from 'state/store.bootstrap'
import { _isometry } from 'state/store.isometry'
import styled from 'styled-components'
import { Tooltip } from 'components/Tooltip/Tooltip'
import { useLocation } from 'react-router-dom'
import { BREAK_ } from 'constants'
import { darken } from 'polished'

export const Arrows = ({frame,setFrame,config,index,setIndex}) => {
  const type = getFocus(_bootstrap, "type");
  const here = useLocation().pathname;
  const isIsometry = here.includes("isometry")
  const [state, setState] = useState(0)
  const [showTooltip, setShowTooltip] = useState(false)
  const isMobile = useBreak('md_dn')
  const SNP = config.IMAGE_SNAPFRAMES 

  const MAX = SNP?.length - 1
  const MIN = 0
  const setRotate_Done = useSet(rotate_by_slider)
  const { pin, pid } = useGet(active_project)
  const buttonsLabel  = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []
  const percent = getIsoData('percent')
  const showOldSlider = useGet(vis_showOldSlider);

  const pids   = useGet(_bootstrap).projectids
  const allConfig     = getFocus(_isometry, 'config')
  
  const setFrameFocus = setFocus(_isometry, 'activeframe')
  const getFrame = getFocus(_isometry, 'activeframe')
  const click = (DIR) => {
    if (showOldSlider) {
      if ((index === MAX && DIR > 0) || (index === MIN && DIR < 0)) return;
    }

    if ( state == 1 ) return
    setState(1)
    const from = (()=>{
      if ( index == MAX && DIR > 0 ) return SNP?.[MIN]
      if ( index == MIN && DIR < 0 ) return SNP?.[MAX]
      return SNP?.[index]     
    })()
    const to = (()=>{
      if ( index == MAX && DIR > 0 ) return SNP?.[1]
      if ( index == MIN && DIR < 0 ) return SNP?.[MAX-1]
      return SNP?.[index+DIR]
    })()
    const toIndex = (()=>{
      if ( index == MAX && DIR > 0 ) { return 1 }
      if ( index == MIN && DIR < 0 ) { return MAX-1 }
      return index+DIR
    })()
    
    setIndex( toIndex )

    if(pin === 0) {
      animate(from, to, {
        duration: 0.9,
        transition: { ease:'ease-in-out' },
        onUpdate: v => {
          if(+v === SNP[toIndex]) setRotate_Done(true)
          setFrameFocus((f) => (pids.map((p, cnfIndex) => {
            const overviewPid = allConfig?.[0]?.[pids[0]]?.ANGLES;
            if(cnfIndex === 0) {
              return {
                [p]: +v.toFixed(0)
              }
            }else {
              return {
                [p]: overviewPid?.[toIndex]?.[p]?.[p]
              }
            }
          })))
        },
        onComplete:() => setState(0)
      })
    }else {
      animate(from, to, {
        duration:0.3,
        transition: { ease:'linear' },
        onUpdate:(v) => {
          if(+v === SNP[toIndex]) setRotate_Done(true)
          setFrame(+v.toFixed(0))
        },
        onComplete:() => setState(0)
      })
    }

    // animate( from, to, {
    //   duration:0.3,
    //   transition: { ease:'linear' },
    //   onUpdate:(v) => {
    //     if(+v === SNP[toIndex]) setRotate_Done(true)
    //     setFrame(+v.toFixed(0))
    //   },
    //   onComplete:() => setState(0)
    // })
  }

  const leftDisabled = showOldSlider && index === MIN;
  const rightDisabled = showOldSlider && index === MAX;
  const animation = {
    initial   : { opacity:0 },
    animate   : { opacity:1 },
    exit      : { opacity:0 },
    transition: { duration:0.5, ease:'linear' },
  }
const props = { type, isIsometry, pin, isMobile}
  return (
    <Main as={motion.div} {...animation}>
        {!isMobile && <Tooltip  showTooltip={showTooltip}/>}
        {!isMobile && percent == 100 && <Label className='h3'>{`${buttonsLabel?.filter(btn => btn.UILabelProperty === 'SkiftVinkel')[0]?.Label}: `}</Label>}
      <ArrowButton variant={"primary"} {...props} clr={clr} onClick={() => click(-1)} onMouseEnter={() => !leftDisabled && setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)} disabled={leftDisabled}>
      <InnerSection disabled={leftDisabled}>
        <Arrow {...props} D={'L'} w={ isMobile ? '25' : '25' } fill={pin===0 && type==="poly" && isIsometry ? isMobile ? clr.primary_text : "#000" : clr?.primary_text }  {...{percent}} disabled={leftDisabled} />
        </InnerSection>
      </ArrowButton>
     
      <ArrowButton variant={"primary"} {...props} clr={clr} isMobile={isMobile} onClick={() => click(+1)} onMouseEnter={() =>!rightDisabled && setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)} disabled={rightDisabled}>
      <InnerSection disabled={rightDisabled}>
        <Arrow {...props} D={'R'} w={ isMobile ? '25' : '25' } fill={pin===0 && type==="poly" && isIsometry ? isMobile ? clr.primary_text : "#000" : clr?.primary_text }  {...{percent}} disabled={rightDisabled}/>
        </InnerSection>
      </ArrowButton>
    </Main>
  )
}


export const Section = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  border: 2px solid ${({ clr, pin, isMobile, isIsometry, type }) => 
    pin === 0 && type === "poly" && isIsometry ? (isMobile ? `${clr?.primary}` : `#fff`) : `${clr?.primary}`};
  background: ${({ clr, pin, isMobile, isIsometry, type }) => 
    pin === 0 && type === "poly" && isIsometry ? (isMobile ? `${clr?.primary}bf` : `#ffffffbf`) : `${clr?.primary}bf`};
  justify-content: center;
  align-items: center;
  gap: 10px;
  line-height: 1;
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
  user-select: none;
  pointer-events: auto;
  opacity: ${({ disabled }) => disabled ? 0.5 : 1};
`
export const ArrowButton = styled.button`

display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
 gap: 10px;
width: 40px;
height: 40px;
border-radius: 8px;
// padding: 12px 40px;
line-height: 1;
user-select: none;
border: ${({ clr, variant }) =>
  variant === "primary" ? "none" : "1px solid #D1D1D1"};

transition: background-color 0.1s ease-in, border 0.1s ease-in, box-shadow 0.1s ease-in;

${BREAK_.md_up} {
  height: 40px;
  // width: ${({ width }) => (width ? width : '205px')};
}

/* Background color logic */
background-color: ${({ variant, clr, pin, type, isIsometry, isMobile }) => {
  if (type === "poly" && pin === 0 && isIsometry) {
    return isMobile && variant === "primary"
      ? `${clr?.primary}`
      : clr?.buttons_text;
  }
  if (type === "mono" && pin === 0 && isIsometry) {
    return isMobile && variant === "secondary"
      ?  `#ffffffbf`
      : `${clr?.primary}`;
  }
  return variant === "primary" ?  `${clr?.primary}`: clr?.buttons_text;
}};

&:hover {
  background-color: ${({ variant, clr, pin, type, isIsometry, isMobile }) => {
    if (type === "poly" && pin === 0 && isIsometry) {
      return isMobile && variant === "primary"
        ? darken(0.04, clr?.primary)
        : `#ffffffbf`;
    }
    if (type === "mono" && pin === 0 && isIsometry) {
      return isMobile && variant === "secondary"
        ? `#ffffffbf`
        : darken(0.04, clr?.primary);
    }
    return variant === "primary" ? darken(0.04, clr?.primary) : "#ffffffbf";
  }};
  
  border: ${({ clr, variant }) =>
    variant === "primary" ? "none" : "1px solid #D1D1D1"};
}


`;