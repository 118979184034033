import { Checkbox } from "@mui/material"

export const CheckBox = ({ name='', checked, onChange, size="16px", sx }) => {
    return <Checkbox
    name={name}
    checked={checked}
    onChange={onChange}
    icon={<Icon size={size} />}
    checkedIcon={<CheckedIcon size={size} />}
    sx={sx}
  />
}

const Icon = ({ size }) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" fill="none">
                <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" fill="white" stroke="#D1D1D1"/>
            </svg>
}

const CheckedIcon = ({ size }) => {
    return <svg width={size} height={size} viewBox="0 0 16 16" fill="none">
                <g id="Statue=True">
                <rect id="Rectangle" width={size} height={size} rx="4" fill={clr?.buttons}/>
                    <g id="Path">
                        <path fillRule="evenodd" clipRule="evenodd" d="M4 9.56573L6.73333 11.8477C6.882 11.9754 7.07788 12.0265 7.26667 11.9868C7.4571 11.9495 7.62257 11.8276 7.72 11.6529L12 4" fill={clr?.buttons}/>
                        <path d="M4 9.56573L6.73333 11.8477C6.882 11.9754 7.07788 12.0265 7.26667 11.9868C7.4571 11.9495 7.62257 11.8276 7.72 11.6529L12 4" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                </g>
            </svg>
}



{/* <svg width={size} height={size} viewBox="0 0 16 16" fill="none">
<g id="Statue=True">
    <rect id="Rectangle" width={size} height={size} rx="4" fill={clr?.buttons} />
    <g id="Path">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4 9.56573L6.73333 11.8477C6.882 11.9754 7.07788 12.0265 7.26667 11.9868C7.4571 11.9495 7.62257 11.8276 7.72 11.6529L12 4" fill="#B07237"/>
        <path d="M4 9.56573L6.73333 11.8477C6.882 11.9754 7.07788 12.0265 7.26667 11.9868C7.4571 11.9495 7.62257 11.8276 7.72 11.6529L12 4" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
</g>
</svg> */}