import { useLayoutEffect, useState } from 'react'

export const useScalePoint = (bounds,dimensions,mode,additionalScalepoints=0, isMobile, pin) => {

  const [res, setRes] = useState(0)

  useLayoutEffect(()=>{

    if ( bounds.width ) {
      const R_map    = ( (dimensions.W / dimensions.H)).toFixed(4)
      const R_bounds = ( (bounds.width / bounds.height) - ( isMobile ? (pin>0 ? 0 : 0.001 ) : (pin>0 ? 0 : 0.09 )) ).toFixed(4)

      const operator = mode ? ( R_bounds < R_map ) : ( R_bounds > R_map )
   
      const scaleFactor = ( operator )
          ? (bounds.width  / dimensions.W) - ( isMobile ? (pin>0 ? 0 : 0.001 ) : (pin>0 ? 0 : 0.09 ))
          : bounds.height / dimensions.H

      setRes( scaleFactor )
    }

  },[bounds,dimensions,mode])

  if (res) return res
}