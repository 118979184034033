import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  useMediaQuery,
} from "@mui/material";
import Unit from "components/Unit/Unit.model";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CustomInput, CustomLabel, CustomRadioGroup, CustomSelect, PlaceHolderInputField  } from "./styled-components";
import SuccessMessage from "./SuccessMessage";
import useHubspot from "./useHubspot";
import { getFocus, useGet } from "state/jotai";
import { active_project } from "state/store.global";
import { _project } from "state/store.projects";
import { useLocation } from "react-router-dom";
import { useFetch } from "hooks/fetch/useFetch";
import { motion } from "framer-motion";
import axios from "axios";
import { Icon } from "components/Icons";
import { ArrowDropDownIcon } from "./FormComponent";
import { ReactSelect } from "components/AdvanceDropdown/NormalDropdown/Dropdown";
import { PhoneNumberDropdown } from "components/AdvanceDropdown/PhoneNumberDropdown/Dropdown";
import { CheckBox } from "components/FormComponent/CheckBox";
import { SearchDropdown } from "components/AdvanceDropdown/SeachDropdown/Dropdown";
import { CustomPlaceHolderInputField } from "components/FormComponent/TextField";
import { CustomRadio } from "components/FormComponent/Radio";
import { CustomButton } from "components/Unit/NewReservationButton";
import { enqueueSnackbar } from "notistack";
import EusButton from "components/Button/EusButton";
import { useBreak } from "hooks/useBreak";
import { GETLEADTOKEN } from "api/api";
import { SelectDate } from "components/FormComponent/SelectDate";


const ContactForm = ({ grid, unit, campaignId, children }) => {
  const sm       = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { i18n: { language }, } = useTranslation();

  const [personName, setPersonName] = useState([]);
  const [nationName, setNationName] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [describeName, setDescribeName] = useState([]);

  // const { submit, successMsg, errorMsg, inProgress, submitted } = useHubspot();
  const { register, handleSubmit, getValues, setValue, watch, control, reset, formState: { errors, isValid } } = useForm({ mode: 'onChange',
    defaultValues: {
      dateOfBirth: "",
      birth_date: "",
      birth_month: "",
      birth_year: ""
    },
   });
  const here = useLocation().pathname;
  const atUnit = here.includes("unit");
  const { pin, pid } = useGet(active_project) 
  const isMobile = useBreak('md_dn')

  const testLeadData = useFetch({ url: GETLEADTOKEN() });

  const buttonsLabel  = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []

  const isDesktop = useMediaQuery('(min-width:1200px)');

  const unitNo = unit?.getValue("Projeknr") ? `${buttonsLabel?.filter( btn => btn?.UILabelProperty === 'Bolignr')[0]?.Label} ${unit.getValue("Projeknr")},` : "";
  const unitAddress = unit?.getAddress() ?? "";
  // const unitId = unit?.getId();
  const sys_uid = unit?.getValue('SVGID');
  
  const homeUrl = window.location.origin;
  const unitUrl = unit?.getUrl(`${homeUrl}/${language}`) ?? "";
  
  
  const [checked, setChecked] = React.useState(false);
  const [checked2, setChecked2] = React.useState(false);

  const handleCheckbox = (event) => { setChecked(event.target.checked); };
  const handleCheckbox2 = (event) => { setChecked2(event.target.checked); };


  useEffect(()=> {
      testLeadData.mutate()
  }, [language])

  useEffect(() => {
    if (submitted) {
      reset();
      setChecked(false);
      setChecked2(false)
    }
  }, [submitted]);


  // describe field options
  const describeOption = {
    'da': [
      "Studerende",
      "Young Professional",
      "Traveling Professional",
      "Expat",
      "B2B",
      "Tidsbegrænset lejer",
      "Andet"
    ],
    'en': [
      "Student",
      "Young Professional",
      "Traveling Professional",
      "Expat",
      "B2B",
      "Temporary Stay",
      "Other",
    ]
  }

  // city status field options
  const city_status = {
    'da': [
      "Single",
      "Gift",
      "Samlevende",
      "Skilt",
      "Enke/enkemand",
      "Separeret",
      "Andet"
    ],
    'en': [
      "Single",
      "Married",
      "Cohabiting",
      "Divorced",
      "Widow/Widower",
      "Separated",
      "Other",
    ]
  }

  // city status id 
  const city_status_id = {
    "Single": "12",
    "Gift": "13",
    "Married": "13",
    "Samlevende": "14",
    "Cohabiting": "14",
    "Skilt": "15",
    "Divorced": "15",
    "Enke/enkemand": "16",
    "Widow/Widower": "16",
    "Separeret": "17",
    "Separated": "17",
    "Andet": "18",
    "Other": "18",
  }

    // city status field options
    const whereHearProperty_status = {
      'da': [
        "Annonce på internettet",
        "Sociale medier",
        "Google-søgning",
        "Avisannonce",
        "Venner/familie",
        "Ejendomsmægler",
        "Skiltning ved ejendommen",
        "Åbent hus arrangement",
        "Andet"
      ],
      'en': [
        "Online advertisement",
        "Social media",
        "Google search",
        "Newspaper ad",
        "Friends/family",
        "Real estate agent",
        "Signage at the property",
        "Open house event",
        "Other",
      ]
    }

  // "where did you hear about the property question" id's
  const whereHearProperty_id = {
    "Annonce på internettet": "19",
    "Online advertisement": "19",
    "Sociale medier": "20",
    "Social media": "20",
    "Google-søgning": "21",
    "Google search": "21",
    "Avisannonce": "22",
    "Newspaper ad": "22",
    "Venner/familie": "23",
    "Friends/family": "23",
    "Ejendomsmægler": "24",
    "Real estate agent": "24",
    "Skiltning ved ejendommen": "25",
    "Signage at the property": "25",
    "Åbent hus arrangement": "26",
    "Open house event": "26",
    "Andet": "27",
    "Other": "27"
  }


  const handleFormSubmit = async (data) => {


  const utms = {
    utm_source: "google",
    utm_medium: "cpc",
    utm_campaign: "spring_sale",
    utm_content: "ad_variation_1"
  }

  let utmParam = {};
  for (let [key, value] of new URLSearchParams(location.search).entries()) {
    if(key === "utm_source" || key === "utm_medium" || key === "utm_campaign" || key === "utm_content") {
      utmParam[key] = value;
    }
  }
 
    // const leadNationality = testLeadData?.D?.countries?.filter((c) => c?.name_dk === data?.nationality_id || c?.name_eng === data?.nationality_id)
    // const leadCountry_code = testLeadData?.D?.countries?.filter((c) => c?.country_code === `+${data?.country_id.split("+")?.[1]}`)
    // const leadNationality = data?.nationality_id?.country_id
    const leadCountry_code = data?.country_id?.country_code
      // console.log(data,'data')
        const payload = new URLSearchParams({
          Token: testLeadData?.D?.token,
          Hash: testLeadData?.D?.hash,
          Campaign: campaignId,
          SYS_UID: sys_uid,
          Fornavne: data.firstname,
          Efternavn: data.lastname,
          Email_privat: data.email,
          SELECTIONS: `${data['AP Kunde']},${data['Barn af AP kunde']}`,
          // country_code: leadCountry_code,
          Telefon_mobil: leadCountry_code + data.phone,
          dob: data.dateOfBirth
          // Comments: data.kommentar?.length ? data.kommentar : "",
          // ...utmParam
        });
        // console.log(leadCountry_code, data,Object.fromEntries(payload))
      axios.post(`${testLeadData?.D?.system_domain}/api2/post_leadtool/`, payload, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then((data) => {
        setSubmitted(Object.keys(data || {}).length ? true : false);
        testLeadData.mutate()
        setPersonName([]);
        setNationName([]);w
        setDescribeName([])
        reset();
        setChecked(false);
        setChecked2(false)
      })
      // .catch((err) => {
      //   console.log(err, 'err')
      //   enqueueSnackbar(language === "da" ? 'Noget gik galt. Prøv igen' : 'Something went wrong. Try again', { variant: 'error' });
      // });
  };

  const onSubmit = (data) => {
    handleFormSubmit(data);
  };

  // select dropdown configs
  
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;


  const MenuProps = {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: !grid ? "right" : "left" // Don't pass grid from contact drawer as this condition won't work
    },
    transformOrigin: {
      vertical: "top",
      horizontal: !grid ? "right" : "left"
    },
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
        // width: dropdown_width
      },
    },
  };




  // handling the field which should have only number allowed
  const handleNumberInput = (event) => {
    return (event.key.charCodeAt() >= 48 && event.key.charCodeAt() <= 57) || event.key === 'Backspace' ? event.key : event.preventDefault();
  }

  return (
    <>
      {submitted ? (
          <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
        >
             <SuccessMessage label={atUnit ? false : false} labelName = {"Signupmessage"} msg={ 'Vi har modtaget din opskrivning - vi kontakter dig hurtigst muligt' } />
        </motion.div>
     
      ) : (
        <>
          <Box
            sx={{
              position: 'relative',
               color   : clr.sub_text_color,
            }}
          >
            {children}
            {/* progress */}
            {/* {inProgress && (
              <Box
                sx={{
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  left: 0,
                  top: 0,
                  width: '100%',
                  height: '100%',
                  zIndex: 2,
                }}
              >

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 100,
                    height: 100,
                    backgroundColor: 'rgba(0,0,0,0.25)',
                  }}
                >
                  <CircularProgress />
                </Box>
              </Box>
            )} */}

            <form onSubmit={handleSubmit(onSubmit)} style={{ background: '#ffffffe6', borderRadius: '6px', padding: '16px' }}>
              <Grid
                container
                columnSpacing={3}
                rowSpacing={grid ? 2 : 1}
                sx={{ mb: 5 }}
              >
                <Grid item xs={12}>
                <Grid sx={{ gap: "16px" }}>
                  <FormControl
                    sx={{ mb: 2, gap: '8px' }}
                    fullWidth
                    variant='filled'
                  >
                    <CustomLabel shrink  className="h5">
                      {buttonsLabel?.filter( btn => btn.UILabelProperty === 'FullName')[0]?.Label || 'Fuldt navn'}&nbsp;<span>*</span>
                    </CustomLabel>
                    <CustomPlaceHolderInputField 
                    error={errors.firstname ? true : false}
                    helperText={buttonsLabel?.filter( btn => btn.UILabelProperty === 'firstNameErrorWarn')[0]?.Label}
                    // label= { buttonsLabel?.filter( btn => btn.UILabelProperty === 'FirstMiddleName')[0]?.Label }
                    label={buttonsLabel?.filter( btn => btn.UILabelProperty === 'FirstMiddleName')[0]?.Label || 'Fornavn og mellemnavn'}
                    register={{...register('firstname', { required: true, validate: value => value.trim() !== "" })}}
                    />
                  </FormControl>
                  <FormControl sx={{ mb: 3 }} fullWidth variant='filled'>
                    <CustomPlaceHolderInputField 
                    helperText={buttonsLabel?.filter( btn => btn.UILabelProperty === 'lastNameErrorWarn')[0]?.Label}
                    error={errors.lastname ? true : false}
                    label={buttonsLabel?.filter( btn => btn.UILabelProperty === 'FormularEfternavn')[0]?.Label}
                    register={{...register('lastname', { required: true, validate: value => value.trim() !== "" })}}
                    />
                  </FormControl>
                </Grid>
                </Grid>
                <Grid item xs={12} sx={{ pt: "0 !important" }}>
                <Grid sx={{ pt: "0 !important" }}>
                  <FormControl
                    sx={{ mb: '24px', gap: '8px' }}
                    fullWidth
                    variant='filled'
                  >
                    <CustomLabel shrink  className="h5">
                    {buttonsLabel?.filter( btn => btn.UILabelProperty === 'PrivateEmail')[0]?.Label || "privat email"}&nbsp;<span>*</span>
                    </CustomLabel>
                    <CustomPlaceHolderInputField
                      error={errors.email ? true : false}
                      helperText={buttonsLabel?.filter( btn => btn.UILabelProperty === 'emailAddressErrorWarn')[0]?.Label}
                      label={buttonsLabel?.filter( btn => btn.UILabelProperty === 'FormularEmail')[0]?.Label}
                      register={{...register('email', { required: true,  validate: (value) => {
                        const trimmedValue = value.trim();  // Trim spaces before and after the email
                        if (!trimmedValue) {
                          return false
                        }
                        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                        if (!emailPattern.test(trimmedValue)) {
                          return false
                        }
                        return true;
                      } })}}
                    />
                  </FormControl>
                </Grid>
                </Grid>
                <Grid item xs={12} sx={{ pt: "0 !important" }}>
                <Grid sx={{ gap: "16px" }}>
                  <FormControl
                    sx={{ mb: 3, gap: '8px' }}
                    fullWidth
                    variant='filled'
                  >
                    <CustomLabel shrink  className="h5">
                    {buttonsLabel?.filter( btn => btn.UILabelProperty === 'PhoneNumber')[0]?.Label || "Telefon nr."}&nbsp;<span>*</span>
                    </CustomLabel>
                    <PhoneNumberDropdown error={errors.country_id || errors.phone ? true : false} helperText={buttonsLabel?.filter( btn => btn.UILabelProperty === 'dropdownErrorWarn')[0]?.Label} options={testLeadData?.D?.countries} control={control} getPhoneId={'country_id'} getPhoneNumber={'phone'} placeholder={ buttonsLabel?.filter( btn => btn.UILabelProperty === 'Country')[0]?.Label || 'Land' } description={buttonsLabel?.filter( btn => btn.UILabelProperty === 'CountryCodeDropdownDescription')[0]?.Label || "Søg landekode"} />
                  </FormControl>
                </Grid>
                </Grid>
                <SelectDate style={{ mb: 2, gap: '8px' }} setValue={setValue} getValues={getValues} watch={watch} control={control} dateOfBirth={'dateOfBirth'} />
                <Grid item xs={12} sx={{ pt: "0 !important" }}>
                <Grid sx={{ pt: "0 !important" }}>
                  <FormControl
                    sx={{ mb: '24px', gap: '8px' }}
                    fullWidth
                    variant='filled'
                    
                  >
                    <CustomLabel shrink  className="h5">
                      Er du kunde i AP Pension?&nbsp;<span>*</span>
                    </CustomLabel>
                    <RadioGroup
                        sx={{ display: 'flex', flexDirection: 'row' }}
                        name="AP Kunde"
                      >
                    <CustomRadio register={{...register('AP Kunde', { required: true })}} label={'Ja'} value={28} />
                    <CustomRadio register={{...register('AP Kunde', { required: true })}} label={'Nej'} value={29} />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                </Grid>
                <Grid item xs={12} sx={{ pt: "0 !important" }}>
                <Grid sx={{ pt: "0 !important" }}>
                  <FormControl
                    sx={{ gap: '8px' }}
                    fullWidth
                    variant='filled'
                    
                  >
                    <CustomLabel shrink  className="h5">
                    Er du barn af kunde i AP Pension?&nbsp;<span>*</span>
                    </CustomLabel>
                    <RadioGroup
                    sx={{ display: 'flex', flexDirection: 'row' }}
                        name="Barn af AP kunde"
                      >
                    <CustomRadio register={{...register('Barn af AP kunde', { required: true })}} label={'Ja'} value={30} />
                    <CustomRadio register={{...register('Barn af AP kunde', { required: true })}} label={'Nej'} value={31} />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                </Grid>
              </Grid>
              <Box sx={{ mb: 3, display: "flex", flexDirection: "column", gap: "8px" }}>
                <CustomLabel className="h4">
                  { buttonsLabel?.filter( btn => btn.UILabelProperty === 'FormularBetingelser')[0]?.Label }&nbsp;<span>*</span>
                </CustomLabel>
                <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                <FormControlLabel
                  sx={{
                    alignItems: 'center',
                    mr: 0,
                    ml: 0,
                    gap: '8px',
                    "span":{    color: '#000' },
                    "a":{color: `${'#000'} !important` },
                  }}
                  control={
                    <CheckBox
                      name='_value'
                      checked={checked}
                      onChange={handleCheckbox}
                      sx={{ p: 0 }}
                    />
                  }
                  label={
                    <Box >
                      <div>
                        <span className="paragraph2-light">{language === "en" ? "Accept our terms and conditions." : "Accepter vores vilkår og betingelser"}&nbsp;</span>
                        <a className="h5" style={{ textDecoration: 'none' }} href={"https://grunwaldshave.dk/betingelser/"} target="_blank">{ buttonsLabel?.filter( btn => btn.UILabelProperty === 'ReadMoreHere')[0]?.Label || 'Læs mere her' }</a>
                      </div>
                    </Box>
                  }
                />
                </Box>
              </Box>
              <Box
                sx={{
                  position: { xs: atUnit ? 'unset' :"fixed", sm: "unset" },
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  zIndex: '5',
                  "button": {
                    width: { xs: atUnit ? 'auto' : "100%", sm: "fit-content" },
                    height: { xs: !atUnit ? "60px" : "44px" , sm: "44px"},
                    padding: { xs: !atUnit ? "12px 40px 30px 40px" : "12px 40px" }
                  }
                }}
              >
              {
                sm && !atUnit 
                ?
                <EusButton
                // {...props}
                clr={clr}
                type='submit'
                onClick={()=> {}}
                borderRadius={"6px 6px 0px 0px;"}
                variant={"primary"}
                iconName={"next-arrow"}
                backgroundColor={clr.buttons}
                disabled={!checked || !isValid }
                icon={true}
                size={12}
                iconAlign={"right"}
                buttonlabel={false}
                otherText={'Send'}
                iconSize={"xl"}
                isFaIcon={false}
              />
                :
                <CustomButton
                type='submit'
                className="eus-button-2"
                clr={clr}
                disabled={  !checked || !isValid}
                variant = "primary"
                padding={"12px 24px"}          
              >
                  {/* { atUnit ? buttonsLabel?.filter( btn => btn.UILabelProperty === 'signUp')[0]?.Label || 'Tilmeld' : "Send" } */}
                  { buttonsLabel?.filter( btn => btn.UILabelProperty === 'signUp')[0]?.Label || 'Tilmeld' }
              </CustomButton>
              }
              </Box>
            </form>
          </Box>
        </>
      )}
    </>
  )
};

ContactForm.propTypes = {
  children: PropTypes.node,
  grid: PropTypes.bool,
  unit: PropTypes.instanceOf(Unit),
};

export default ContactForm;
