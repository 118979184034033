import { Box, Typography, } from "@mui/material";
import ContactForm from "components/Contact/ContactForm";
import PropTypes from "prop-types";
import React from "react";
import Unit from "./Unit.model";
import { getFocus, useGet } from "state/jotai";
import { active_project } from "state/store.global";
import { _project } from "state/store.projects";

function SignUpForm({ unit }) {
  const { pin, pid } = useGet(active_project) 
  const buttonsLabel  = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []

  return (
    <Box id="unit_page_contact_form" sx={{ pt: {xs: 3, lg: 5}, pb: { md: 5, xs: 0 }, px: { xs: 3, lg: '45px' } }} >
      <Box sx={{  maxWidth: 514, mx: "auto", }} >
        <ContactForm campaignId={'ccc8abe4718ce8a1d45ad19f695eb884'} grid unit={unit}>
          <Box sx={{ mb: 3 }} >
            <Box className="h3">
              {/* { buttonsLabel?.filter( btn => btn.UILabelProperty === 'SidefodformularOverskrift')[0]?.Label } */}
              Tilmelding til interesselisten
            </Box>
            <br />
            <Box className="paragraph1-regular" >
            {/* { buttonsLabel?.filter( btn => btn.UILabelProperty === 'SidefodformularUnderoverskrift')[0]?.Label } */}
            Vil du gerne komme først i køen til at modtage nyheder og opdateringer? Udfyld formularen nedenfor for at blive tilføjet til interesselisten
            </Box>
          </Box>
        </ContactForm>
      </Box>
    </Box>
  );
}

SignUpForm.propTypes = {
  unit: PropTypes.instanceOf(Unit).isRequired,
};

export default SignUpForm;
