export const get = (units, property) => units?.Data?.[property]?.Value ?? null;

export const label = (units, property) => units?.Data?.[property]?.Label ?? "";

export const uget = (units, property) =>
  units?.Data?.[property]?.UnformattedValue ?? null;

export const floorplan = (i, alternative) =>
  i?.Gallery?.Floorplan?.UrlThumbnail ?? alternative;

export const ugetValue = (units, property) =>
  units?.Data?.[property]?.Value ?? null;

export const rent = (i) =>  ugetValue(i,'SalesTypeLejelejlighed')

export const s2c = (status) => {
  if (status.toUpperCase() == "LEDIG") return "#00FF0080";
  if (status.toUpperCase() == "TIL LEJE") return "#00FF0080";
  if (status.toUpperCase() == "UDLEJET") return "#FF000080";
  if (status.toUpperCase() == "RESERVERET") return "#FFA50080";
  return "#ababab";
};

const postfix = "/md.";
const monetary = " kr.";

export const formatters = {
  Index: (j, _) => j + 1,
  Pris: (i, x) => {
    const isRent = x == "Husleje";
    const isString = typeof uget(i, x) == "string";
    const rent = isString ? "-" : get(i, x) + monetary + postfix;
    const price = isString ? "-" : get(i, x) + monetary;
    return isRent ? rent : price;
  },
  Status: (i, x) => s2c(get(i, "UnitStatusName")),
  Etage: (i, x) => {
    // clog( 'i', i, 'x', x )
    return get(i, x) == "err" ? get(i, "Etage") + ". sal" : get(i, x);
  },
  Areal: (i, x) => (get(i, x) ? get(i, x) + " m²" : ""),
  Rooms: (i, x) => get(i, x) + " vær.",
  Else: (i, x) => get(i, x),
};
