import { API, SESSION } from "api/api";
import { useEffect, useRef } from "react";
import { useAtom, useGet } from "state/jotai";
import { _bootstrap } from "state/store.bootstrap";
import { ip } from "state/store.global";
import moment from "moment";
import { session_token } from "state/store.logging_sesson";

export const SessionLogging = () => {
  const id = useGet(_bootstrap)?.projectids;
  const IP = useGet(ip); // Fetch IP from the store
  const startTimestamp = useRef(moment().format("YYYY-MM-DD HH:mm:ss")); // Start time
  const [token, setToken] = useAtom(session_token);
  const hasSessionStarted = useRef(false); // Track if session is started
  const sessionInterval = useRef(null); // For periodic pings

  useEffect(() => {
    const startSession = async () => {
      if (!IP || !id?.[0] || hasSessionStarted.current) {
        console.log("Skipping session start: Missing data or already initialized");
        return;
      }

      hasSessionStarted.current = true;

      const initialData = {
        SessionToken: null,
        UserIdentifier: "Ei.DO",
        SessionStart: startTimestamp.current,
        SessionEnd: "0000-00-00 00:00:00",
        IPAddress: IP,
        ProjectID: id?.[0],
        DatabaseSourceId: "1",
        SystemSourceId: "3",
      };

      try {
        const response = await fetch(`${API}${SESSION}`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(initialData),
        });

        if (response.ok) {
          const responseData = await response.json();
          setToken(responseData?.sessionToken);
          console.log("Session started successfully:", responseData);
        } else {
          console.error("Failed to start session:", response.statusText);
        }
      } catch (error) {
        console.error("Error in starting session:", error);
      }
    };

    const handleSessionEnd = () => {
      if (!token || !IP || !id?.[0]) {
        console.log("Skipping session end: Missing data or session not started");
        return;
      }

      const endTimestamp = moment().format("YYYY-MM-DD HH:mm:ss");
      const endData = {
        SessionToken: token,
        UserIdentifier: "Ei.DO",
        SessionStart: startTimestamp.current,
        SessionEnd: endTimestamp,
        IPAddress: IP,
        ProjectID: id?.[0],
        DatabaseSourceId: "1",
        SystemSourceId: "3",
      };

      console.log("Ending session:", endData);

      try {
        if (navigator.sendBeacon) 
          {
          const blob = new Blob([JSON.stringify(endData)], { type: "application/json" });
          navigator.sendBeacon(`${API}${SESSION}`, blob);
          console.log(endData,"endddddddddd")
        } 
        else {
          // Fallback to fetch
          fetch(`${API}${SESSION}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(endData),
          }).catch((error) => console.error("Error in fallback fetch:", error));
        
        }
      } catch (error) {
        console.error("Error in ending session:", error);
      }
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        handleSessionEnd();
      }
    };

    startSession();

    // Set up event listeners for session end
    window.addEventListener("beforeunload", handleSessionEnd);
    // document.addEventListener("visibilitychange", handleVisibilityChange);

    // Periodic session update to ensure data consistency
    sessionInterval.current = setInterval(() => {
      if (token) {
        console.log("Session active: Periodic update sent");
      }
    }, 30000); // Adjust interval as needed (e.g., 30 seconds)

    // Cleanup
    return () => {
      clearInterval(sessionInterval.current);
      handleSessionEnd();
      window.removeEventListener("beforeunload", handleSessionEnd);
      // document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [id, IP, token, setToken]);

  return null; // No UI rendering needed
};
