import styled from 'styled-components/macro'
import { Icon } from 'assets/Icons_'
import { active_project, iso_free, iso_isrotating } from 'state/store.global'
import { getFocus, useAtom, useGet } from 'state/jotai'
import { useBreak } from 'hooks/useBreak'
import { BREAK_ } from 'constants'
import { _project } from 'state/store.projects'
import { OverviewBack } from './OverviewBack'

export const Back = ({}) => {
  const [rmode, setRmode] = useAtom(iso_free)
  const [isrotate, setIsrotate] = useAtom(iso_isrotating)

  const { pin, pid } = useGet(active_project) 
  const buttonsLabel  = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []

  const isMobile = useBreak('md_dn')
  const click = () => {
    setRmode(false)
    setIsrotate(false)
  }


  return (
    // <Box onClick={click}> 
    //   <Icon icon='new_back_arrow' size={isMobile ? '18' : '18'} color='#000' />
    //   <Text>{ buttonsLabel?.filter( btn => btn.UILabelProperty === 'Tilbage')[0]?.Label }</Text>
    // </Box>
    <Box>
      <OverviewBack />
    </Box>
  )
}

const Text = styled.div`
  white-space: nowrap;
  font-size: 16px;
  font-weight: 400px;  
  /* border: 1px solid black; */
`
const Box = styled.div`
  gap: 5px;
  cursor: pointer;
  padding: 5px 15px;
  z-index: 5;
  align-items: center;
  display: flex;
  gap:5px;
  user-select:none;
  ${ BREAK_.md_up } {
    position: absolute;
    // top: 105px;
    // left: 40px;
    top: 60px;
    left: 0px;
    padding:0;
  }
`