import { Box } from "@mui/material";
import { FlexBox, FullWidth, Section } from "./DualMenu.style";
import { useBreak } from "hooks/useBreak";
import { useLocation, useNavigate } from "react-router-dom";
import { getFocus, useAtom, useGet } from "state/jotai";
import { _bootstrap } from "state/store.bootstrap";
import { Icon } from "assets/Icons_";
import { SelectFunction } from "components/SelectFunction/SelectFunction";
import { ShiftProperty } from "components/ShiftProperty/ShiftProperty";
import { Controls } from "components/Isometry/Isometry.Controls";
import { getIsoData, setIsoData } from "state/jotai.isometryDataHooks";
import { Status } from "components/StatusPopup/StatusPopup";
import { useTranslation } from "react-i18next";
import { currentActiveProject, params_query } from "state/store.global";
import { PoweredBy } from "components/PoweredBy/PoweredBy";
import EusButton, { ETStyledButton } from "components/Button/EusButton";
import { ArrowButton } from "components/Isometry/Isometry.Controls.Arrows";

export const IsometryDualMenu = ({
  left,
  right,
  nav,
  pin,
  openSelectFunction,
  showSelectFunction,
  setShowSelectFunction,
  clickList,
  list,
  buttonsLabel,
  showProperty,
  setShowProperty,
  toggleDrawer,
  config,
  rotatemode,
  click2,
  turnBuilding,
  isrotate,
  click1,
  clickModel,
  model,
  filterShouldShow,
  clickFilter,
  filter,
  state,
  setState,
  closeDrawer,
  setShowFilter,
  setShowMenu,
  showOldSlider,
}) => {
  const type = getFocus(_bootstrap, "type");
  const navigate = useNavigate();
  const params = useGet(params_query);
  const names = getFocus(_bootstrap, "projectnames");
  const {
    i18n: { language },
  } = useTranslation();
const isStatus = true;
  const isMobile = useBreak("md_dn");
  const here = useLocation().pathname;
  const isIsometry = here.includes("isometry");

  const frame = getIsoData("activeframe");
 
  const frames = getIsoData("frames");
  const index = getIsoData("snapindex");
  const setIndex = setIsoData("snapindex");
  const setFrame = setIsoData("activeframe");
  const percent = getIsoData("percent");
  const setActiveProject = useAtom(currentActiveProject)[1];

  // console.log(buttonsLabel,"buttonsss")

  const showButton = [
    "list",
    "compare",
    "favorites",
    "gallery",
    "downloads",
  ].some((i) => here.includes(i));

  const props = { type, isIsometry, pin, isMobile };
  return (
    <>
      <FullWidth
        isMobile={isMobile}
        left={left}
        right={right}
        nav={nav}
        clr={clr}
        isBgTrue={true}
      >
        <FlexBox clr={clr} isIsometry={isIsometry}>
          {isMobile ? (
            <>
              {type === "poly" && pin > 0 && rotatemode ? (
                <Section
                  padding="0 30px"
                  {...props}
                  clr={clr}
                  onClick={rotatemode && isrotate ? click1 : click2}
                >
                  <Icon
                    icon={isrotate ? "pause" : "play"}
                    color={clr?.primary_text}
                    isFaIcon={true}
                    size="lg"
                  />{" "}
                  <p style={{ color: clr?.primary_text }}>
                    {isrotate ? "play rotation" : "Start rotation"}
                  </p>
                </Section>
              ) : type === "mono" && turnBuilding ? (
                <Section
                  padding="0 30px"
                  {...props}
                  clr={clr}
                  onClick={rotatemode && isrotate ? click1 : click2}
                >
                  <Icon
                    icon={isrotate ? "pause" : "play"}
                    color={clr?.primary_text}
                    isFaIcon={true}
                    size="lg"
                  />{" "}
                  <p style={{ color: clr?.primary_text }}>
                    {isrotate ? "Stop rotation" : "Start rotation"}
                  </p>
                </Section>
              ) : (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: isIsometry
                        ? "flex-start"
                        : "space-between",
                      gap: "8px",
                      position: "relative",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    {isIsometry && (
                      <ArrowButton
                      variant={"primary"}
                      clr={clr}
                      onClick={openSelectFunction}
                      padding="18px"
                    >
                      <span
                        style={{
                          fontSize: "23px", // This will enlarge the icon
                          display: "inline-flex", // Ensures proper alignment
                        }}
                      >
                        <Icon
                          icon="ellipsisvertical"
                          color={clr.primary_text}
                          isFaIcon={true}
                          size="xl"
                          wrap     = {{ transform: showSelectFunction ? 'rotate(90deg)' : 'rotate(0deg)'}}
                        />
                      </span>
                      <SelectFunction
                        showSelectFunction={showSelectFunction}
                        setShowSelectFunction={setShowSelectFunction}
                      />
                    </ArrowButton>
                    )}
                    {isIsometry ? (
                      <EusButton
                        {...props}
                        clr={clr}
                        active={here.includes("list")}
                        onClick={clickList}
                        variant={"primary"}
                        iconName={"list"}
                        icon={true}
                        buttonText="Boligliste"
                        buttonlabel={true}
                        iconSize={"xl"}
                        isFaIcon={true}
                        padding={"12px 40px"}
                      />
                    ) : type === "poly" && pin === 0 ? (
                      <Section
                        padding={"18px 30px"}
                        pin={pin}
                        clr={clr}
                        showButton={showButton}
                        active={true}
                        onClick={clickModel}
                      >
                        <Icon
                          size={"lg"}
                          color={clr?.primary_text}
                          icon="modelover"
                          isFaIcon={true}
                        />
                        <div
                          className="eus-button-2"
                          style={{ color: clr?.primary_text }}
                        >
                          Oversigtskort
                        </div>
                      </Section>
                    ) : (
                        <EusButton
                        pin={pin}
                        clr={clr}
                        showButton={showButton}
                        active={true}
                        onClick={clickModel}
                        variant={"primary"}
                        iconName={"model"}
                        icon={true}
                        buttonText="isometri"
                        buttonlabel={true}
                        iconSize={"25"}
                        isFaIcon={false}
                      />
                    )}
                  </Box>
                  {/* We don't need shift angle in this eido hjornekarreen */}
                  {isIsometry && (
                    <Box>
                      <Section width="none">
                        {!rotatemode && percent == 100 && (
                          <>
                            {config?.IMAGE_SNAPFRAMES?.length > 1 && (
                              <Controls
                                {...{
                                  frame,
                                  setFrame,
                                  config,
                                  frames,
                                  index,
                                  setIndex,
                                }}
                              />
                            )}
                          </>
                        )}
                      </Section>
                    </Box>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <Box
                sx={{
                  width: isMobile && !isIsometry ? "100%" : "auto",
                  display: "flex",
                  justifyContent:
                    isMobile && !isIsometry ? "space-between" : "flex-start",
                  gap: "16px",
                  position: "relative",
                }}
              >
                {turnBuilding ? (
                  <>
                    <Section
                      {...props}
                      clr={clr}
                      onClick={rotatemode && isrotate ? click1 : click2}
                    >
                      <Icon
                        icon={isrotate ? "pause" : "play"}
                        color={clr?.primary_text}
                        isFaIcon={true}
                        size="lg"
                      />
                      <p style={{ color: clr?.primary_text }}>
                        {isrotate ? "Stop rotation" : "Start rotation"}
                      </p>
                    </Section>
                  </>
                ) : (
                  <>
                    {isIsometry ? (
                      <EusButton
                        {...props}
                        clr={clr}
                        active={here.includes("list")}
                        onClick={clickList}
                        variant={"primary"}
                        iconName={"list"}
                        icon={true}
                        buttonText="Boligliste"
                        buttonlabel={true}
                        iconSize={"xl"}
                        isFaIcon={true}
                        padding={"12px 40px"}
                      />
                    ) : type === "poly" && pin === 0 ? (
                      <Section
                        padding={"18px 30px"}
                        pin={pin}
                        clr={clr}
                        showButton={showButton}
                        active={true}
                        onClick={clickModel}
                      >
                        <Icon
                          size={"lg"}
                          color={clr?.primary_text}
                          icon="modelover"
                          isFaIcon={true}
                        />
                        <div
                          className="eus-button-2"
                          style={{ color: clr?.primary_text }}
                        >
                          Oversigtskort
                        </div>
                      </Section>
                    ) : (
                      <EusButton
                        pin={pin}
                        clr={clr}
                        showButton={showButton}
                        active={true}
                        onClick={clickModel}
                        variant={"primary"}
                        iconName={"model"}
                        icon={true}
                        buttonText="isometri"
                        buttonlabel={true}
                        iconSize={"25"}
                        isFaIcon={false}
                      />
                    )}
                    {filterShouldShow && !isMobile && (
                      <EusButton
                        iconName={"filters"}
                        icon={true}
                        clr={clr}
                        buttonText="Anvendfilter"
                        buttonlabel={true}
                        onClick={clickFilter}
                        variant={"primary"}
                        pin={pin}
                        iconSize={"xl"}
                        showButton={showButton}
                        isFaIcon={true}
                      />
                    )}

                    {/* UnComment this whenever we have all property on else comment ShiftProperty component section */}
                    {type === "poly" && pin > 0 && (
                      <Section
                        pin={pin}
                        clr={clr}
                        showButton={showButton}
                        active={true}
                        onClick={() => {
                          closeDrawer("detail");
                          setShowFilter(false);
                          setShowMenu(false);
                          setActiveProject("");
                          navigate(
                            type == "mono"
                              ? `/${language}/isometry${
                                  params.length ? params : ""
                                }`
                              : `/${language + names[language][0].path}${
                                  params.length ? params : ""
                                }`
                          );
                        }}
                      >
                        <Icon
                          size={"lg"}
                          color={clr?.primary_text}
                          icon="modelover"
                          isFaIcon={true}
                        />
                        <div
                          className="eus-button-2"
                          style={{ color: clr?.primary_text }}
                        >
                          Oversigtskort
                        </div>
                      </Section>
                    )}

                    {/* remove this pin when we have property more than 1 */}

                    {type === "poly" && (
                      <Section
                        pin={pin}
                        onClick={() => {
                          setShowProperty(!showProperty);
                          toggleDrawer();
                        }}
                        clr={clr}
                        showButton={showButton}
                      >
                        <Icon
                          icon="buildings"
                          size="lg"
                          color={
                            pin > 0 || showButton ? "#fff" : clr?.primary_text
                          }
                          isFaIcon={true}
                        />
                        <div
                          className="eus-button-2"
                          style={{ color: clr?.primary_text }}
                        >
                          Skift ejendom
                        </div>
                        <ShiftProperty
                          showProperty={showProperty}
                          setShowProperty={setShowProperty}
                        />
                      </Section>
                    )}

                    {type === "poly" && pin !== 0 && (
                      <Section
                        pin={pin}
                        clr={clr}
                        onClick={() => isMobile && setState(!state)}
                        onMouseEnter={() => !isMobile && setState(true)}
                        onMouseLeave={() => !isMobile && setState(false)}
                      >
                        <Status
                          pin={pin}
                          state={state}
                          setState={setState}
                          isMobile={isMobile}
                          {...{ percent }}
                        />
                      </Section>
                    )}
                    {type === "mono" && (
                      <ETStyledButton
                        isStatus={isStatus}
                        pin={pin}
                        clr={clr}
                        onClick={() => isMobile && setState(!state)}
                        onMouseEnter={() => !isMobile && setState(true)}
                        onMouseLeave={() => !isMobile && setState(false)}
                        variant={isMobile ? "secondary" : "primary"}
                      >
                        <Status
                          pin={pin}
                          state={state}
                          setState={setState}
                          isMobile={isMobile}
                          {...{ percent }}
                        />
                      </ETStyledButton>
                    )}
                    {/* we don't need rotation button in hjornekarreen eido */}
                    {config?.IMAGE_SNAPFRAMES?.length > 0 &&
                      isIsometry &&
                      !isMobile &&
                      !showOldSlider && (
                        <Section
                          {...props}
                          clr={clr}
                          onClick={rotatemode && isrotate ? click1 : click2}
                        >
                          <>
                            {!turnBuilding ? (
                              <>
                                <Icon
                                  icon="rightarrowrotate"
                                  color={clr?.primary_text}
                                  isFaIcon={true}
                                  size="lg"
                                />{" "}
                                <div
                                  className="eus-button-2"
                                  style={{ color: clr?.primary_text }}
                                >
                                  {
                                    buttonsLabel?.filter(
                                      (btn) =>
                                        btn?.UILabelProperty === "Drejbygning"
                                    )[0]?.Label
                                  }
                                </div>
                              </>
                            ) : (
                              <>
                                <Icon
                                  icon={isrotate ? "pause" : "play"}
                                  color={clr?.primary_text}
                                  isFaIcon={true}
                                  size="lg"
                                />{" "}
                                <p style={{ color: clr?.primary_text }}>
                                  {isrotate
                                    ? "Stop rotation"
                                    : "Start rotation"}
                                </p>
                              </>
                            )}
                          </>
                        </Section>
                      )}
                  </>
                )}
              </Box>
              {!turnBuilding && isIsometry && (
                <Box>
                  <Section width="none">
                    {!rotatemode && percent == 100 && (
                      <>
                        {config?.IMAGE_SNAPFRAMES?.length > 1 && (
                          <Controls
                            {...{
                              frame,
                              setFrame,
                              config,
                              frames,
                              index,
                              setIndex,
                            }}
                          />
                        )}
                      </>
                    )}
                  </Section>
                </Box>
              )}
            </>
          )}
        </FlexBox>
        <PoweredBy clr="#FFF" padding="0px 0 8px 0" />
      </FullWidth>
    </>
  );
};
