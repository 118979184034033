import styled from "styled-components/macro";
import { Icon } from "assets/Icons_";
import {
  active_project,
  current_language,
  currentActiveProject,
  iso_free,
  iso_isrotating,
  params_query,
  turn_building,
} from "state/store.global";
import { getFocus, useAtom, useGet } from "state/jotai";
import { useBreak } from "hooks/useBreak";
import { BREAK_ } from "constants";
import { _project } from "state/store.projects";
import { useNavigate } from "react-router-dom";
import { _bootstrap } from "state/store.bootstrap";

export const OverviewBack = ({}) => {
  const navigateTo        = useNavigate();
  
  const params            = useGet(params_query);
  const type              = getFocus(_bootstrap, "type");
  const currentLanguage   = useAtom(current_language)[0];
  const setActiveProject  = useAtom(currentActiveProject)[1];
  const { pin, pid }      = useGet(active_project);
  const buttonsLabel      = getFocus(_project, "buttonsLabel")?.[pin]?.[pid] ?? [];
  const rmode             = useGet(iso_free);
  const setRmode          = useAtom(iso_free)[1];
  const setIsrotate       = useAtom(iso_isrotating)[1];
  const setIsTurnBuilding = useAtom(turn_building)[1];
  const isrotate          = useGet(iso_isrotating);

  const isMobile = useBreak("md_dn");

  const click1 = () => {
    setActiveProject("");
    navigateTo(`/${currentLanguage}/isometry${params.length ? params : ''}`);
  };

  const click2 = () => {
    setRmode(false);
    setIsrotate(false);
    setIsTurnBuilding(false);
  };

  const props = { rmode, pin, type, isrotate }
  return (
    <>
      {!rmode ? (
        <>
        {pin>0 && <Box onClick={click1} { ...props }>
          <Icon icon = "new_back_arrow"  size = {isMobile ? "18" : "18"} color = "#000" />
          <Text pin  = {pin} type             = {type}>
            {
          buttonsLabel?.filter(
            (btn) => btn.UILabelProperty === "TilbageOversigt"
          )[0]?.Label
        }
          </Text>
        </Box>}
        </>
      ) : (
        <Box  onClick = {click2} { ...props }>
        <Icon icon    = "new_back_arrow" size = {isMobile ? "18" : "18"} color = {"#000"}/>
        <Text pin     = {pin} type            = {type}>
            {
              buttonsLabel?.filter(
                (btn) => btn.UILabelProperty === "Tilbage"
              )[0]?.Label
            }
          </Text>
        </Box>
      )}
    </>
  );
};

const Text = styled.div`
  white-space: nowrap;
  font-size  : 16px;
  font-weight: 400px;
  color      : #000;
`;
const Box = styled.div`
  gap        : 5px;
  cursor     : pointer;
  padding    : 5px 16px;
  z-index    : 5;
  align-items: center;
  display    : flex;
  gap        : 5px;
  user-select: none;
  position   : absolute;
  top        : ${({ pin, rmode, type }) => ((pin > 0 || type === 'mono') && rmode ? '15px' : '105px')};
  left       : ${({ pin, type }) => ((pin > 0 || type === 'mono') ? '14px' : '60px')};
  ${BREAK_.md_up} {
    position: absolute;
    top     : ${({pin}) => pin>0 ?'30px' :'24px'};
    left    : ${({pin}) => pin>0 ?'48px' :'48px'};
  };
  background-color: #ffffffbf;
  border-radius   : 6px;
  padding         : 8px;
  alignItems      : center;
`;
