const palette = {
  primary: {
    main: "#D10000",
    contrastText: "#FFCBDD",
  },
  secondary: {
    main: "#7C0B2B",
    contrastText: "#ffffff",
  },
  text: {
    primary: "#000000",
    
  },
  success: {
    main: "#0E912C",
  },
  common: {
    black: "#000000",
    white: "#FFFFFF",
  },
};

export default palette;
