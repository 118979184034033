import styled from 'styled-components/macro'
import { BREAK_ } from 'constants'


export const Label = styled.div`
  // font-size: 20px;
  text-align: center;
  // font-weight: 600;
  padding: 0 24px;
  ${BREAK_.md_dn} {
    // font-size: 18px;
    padding: 0 15px;
  }
  /* border: 1px solid black; */
`


export const Main = styled.div`
  display: flex;
  gap: 8px;
  bottom: 24px;
  ${BREAK_.md_dn} {
    bottom: 60px;
  }
  right: 40px;
  align-items: center;
  justify-content: end;
  background: transparent;
  /* border: 10px solid black; */
`

export const InnerSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  pointer-events: ${({ disabled }) => disabled ? 'not-allowed' : 'auto'};
`
